import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día 39",
  week: "Semana 6",
  month: "abr",
  day: "21",
  monthNumber: "04",
  date: "2020-04-21",
  path: "/cronologia/semana-06#dia-21-abr",
};
const Day39 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModImage
          src="/images/svg/datafact/20-04-datafact-clm.svg"
          alt="incidencia acumulada corvid-19 en castilla la mancha"
        />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-21"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.968 nuevos positivos y 430 personas fallecidas.
          Permanecen hospitalizadas 77.103 personas, 591 más que el día
          anterior, y 1.927 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.705, lo que supone un aumento de 7 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,4 % y la tasa de recuperados del 40,4 %.
        </ModText>
        <ModGraph
          name="21-04-confirmados-cm-provinicas"
          alt="confirmados por coronavirus provincias castilla la mancha"
        />
        <ModText>
          En las últimas semanas, Castilla La Mancha presenta un crecimiento
          mayor de casos que el resto de comunidades.
        </ModText>
        <ModText>
          El Ministro de Sanidad ha anunciado que los niños menores de 14 años
          podrán salir a dar paseos a partir de este mismo domingo 26,
          acompañados de algún adulto con el que convivan habitualmente.
          Asimismo, también podrán acompañar a los adultos a realizar las
          actividades permitidas durante el estado de alarma, como ir la compra,
          a la farmacia, al centro de salud o a por el periódico.
        </ModText>
        <ModImage src="/images/svg/22-abr-paseo_nenes.svg" alt="paseo niños" />
        <ModTwoCols
          src="/images/svg/28_mar_ministerio.svg"
          alt="Menores saldrán acompañados de un adulto"
          small={false}
        >
          El Gobierno ha aprobado 30 medidas adicionales para proteger a los
          ciudadanos y las empresas, y para apoyar a otros sectores más
          específicos, como la investigación y la ciencia.
        </ModTwoCols>
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.boe.es/buscar/act.php?id=BOE-A-2020-4442"
            name="Orden SND/344/2020 del Ministerio de Sanidad"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/210420-enlace-medidas.aspx"
            name="Medidas económicas y sociales"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day39;
